<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading">
    </PageHeaderSlot>
    <v-row class="mt-6">
      <v-col cols="12">
        <h3>{{ $validate.DataValid(id) ? '管理員詳細' : '新增管理員' }}</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="admin-form" @submit.prevent="formSubmit">
          <v-card class="mb-8">
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="string"
                    :inputValue.sync="username"
                    label="管理員帳號"
                    :required="true"
                    :disabled="$validate.DataValid(id)"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="select"
                    :inputValue.sync="admin_type"
                    label="管理員種類"
                    :options="adminTypeList"
                    :required="true"
                  />
                </v-col>
                <v-col v-if="$validate.DataValid(id)" md="6" cols="12">
                  <FormControl
                    inputType="string"
                    :inputValue.sync="old_password"
                    label="舊密碼"
                    :required="!$validate.DataValid(id) || $validate.DataValid(new_password)"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl
                    inputType="string"
                    :inputValue.sync="new_password"
                    :label="$validate.DataValid(id) ? '新密碼' : '密碼'"
                    :required="!$validate.DataValid(id) || $validate.DataValid(old_password)"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="submitLoading">
            儲存
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl'
export default {
  name: 'AdminDetail',
  components: {
    FormControl,
  },
  data: () => ({
    submitLoading: false,
    username: '',
    old_password: '',
    new_password: '',
    admin_type: '',
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    adminTypeList() {
      return this.$adminTypeList
    },
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getAdminDetail() {
      let adminError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          check_verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_admin_by_id', payload)
        this.$func.log('-----Get Admin Detail-----')
        this.$func.log(result)

        this.username = result.username
        this.admin_type = result.admin_type

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Admin Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          adminError = true
          message = '沒有權限'
        } else {
          adminError = true
        }
      }

      if (adminError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'AdminList',
        })
      }
    },
    async formSubmit() {
      if (!this.submitLoading) {
        this.submitLoading = true

        const isValid = this.$refs['admin-form'].validate()

        if (!isValid) {
          this.submitLoading = false
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            data_type: 'admin_data',
            status: 'active',
            data_status: 'active',
            verify_token: this.getAdminToken(),
            admin_type: this.admin_type,
          }

          let key = ''

          let updatePW = false

          if (this.$validate.DataValid(this.id)) {
            if (this.$validate.DataValid(this.old_password) && this.$validate.DataValid(this.new_password)) {
              payload.old_password = this.old_password
              payload.new_password = this.new_password

              updatePW = true
            }

            payload.id = parseInt(this.id)
            key = 'cms_update_admin'
          } else {
            payload.username = this.username
            payload.password = this.new_password

            key = 'cms_add_admin'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('--- update admin data ---')
          this.$func.log(result)

          if (this.$validate.DataValid(this.id)) {
            if (updatePW) {
              this.setDialogMessage({
                message: '儲存成功，請重新登入',
                isError: false,
                returnLink: { name: 'login' },
              })
              this.setShowDialog(true)
              return
            }
          }

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'AdminList' },
          })
          this.setShowDialog(true)

          this.submitLoading = false
        } catch (error) {
          this.$func.log('--- update admin data fail ---')
          this.$func.log(error)

          let msg = '儲存失敗'

          if (error.data === 'admin verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'username exists') {
            msg = '管理員帳號已存在'
          } else if (error.data === 'admin not found') {
            msg = '管理員不存在'
          } else if (error.data === 'new password cannot be empty') {
            msg = '新密碼不可為空'
          } else if (error.data === 'incorrect old password') {
            msg = '舊密碼不正確'
          }

          this.setDialogMessage({
            message: msg,
            isError: false,
            returnLink: null,
          })
          this.setShowDialog(true)

          this.submitLoading = false
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getAdminDetail()
      }
    }
  },
}
</script>
